<template>
  <div>
    <documentationView :question-list="questionList" redirect="syllabus-manager-documentation" />
  </div>
</template>

<script>
import documentationView from '@/src/router/layouts/app-document/documentationView.vue'
import SYLLABUS_MANAGER_DATA from '@/src/router/layouts/app-document/syllabus-automater-documentation/syllabus-manager.json'
export default {
  name: 'Questions',
  components: {
    documentationView,
  },
  data() {
    return {
      questionList: SYLLABUS_MANAGER_DATA[this.$route.params.id],
    }
  },
}
</script>

<template>
  <div>
    <UIExit label="Back to Previous page" :exit-url="redirect" />
    <div class="mx-auto px-8 md:px-15 lg:px-20 xl:px-24 h-73vh overflow-y-auto bg-white border">
      <div class="list-decimal list-inside">
        <div v-for="(value, idx) in questionList" :key="idx">
          <div
            v-if="value.title"
            class="font-bold text-rich-black text-1xl sm:text-2xl md:text-4xl text-center py-5"
          >
            {{ value.title }}
          </div>
          <div v-if="value.question" class="flex gap-2 mb-3 items-center">
            <span class="text-lg leading-34 font-bold">
              {{ value.question }}
            </span>
          </div>
          <div v-if="questionList" class="flex gap-2">
            <div>
              <div v-if="value.answer">
                <p
                  v-if="isString(value.answer)"
                  class="font-normal md:text-lg text-sm text-dark-silver mb-3"
                >
                  {{ value.answer }}
                </p>
                <div v-else class="font-normal md:text-lg text-sm text-dark-silver">
                  <div v-for="(item, index) in value.answer" :key="index">
                    <P v-if="item.answerSubHeading" class="font-bold md:text-lg text-sm">
                      {{ item.answerSubHeading }}
                    </P>
                    <p
                      v-if="item.answerParagraph"
                      class="font-normal md:text-lg text-sm text-dark-silver"
                    >
                      {{ item.answerParagraph }}
                    </p>
                    <ul v-if="item.answerPoint" class="list-decimal list-inside">
                      <li
                        v-for="(Point, ind) in item.answerPoint"
                        :key="ind"
                        class="font-bold md:text-lg text-sm text-dark-silver"
                      >
                        {{ Point }}
                      </li>
                    </ul>
                    <div v-if="item.imge" class="px-5 md:px-25 py-5">
                      <img
                        class="my-3 border p-5"
                        :src="require('@/src/assets/images/documentation/' + item.imge)"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UIExit from '@/src/components/UiElements/UIExit.vue'
import { isString } from 'lodash'
import ROUTE from '@/src/constants/route-constants.js'

export default {
  name: 'Documentation',
  components: { UIExit },
  props: {
    questionList: {
      type: Array,
      default: () => [],
    },
    redirect: {
      type: String,
      default: ROUTE.QUICK_ACTIONS.name,
    },
  },
  mounted() {
    this.$nextTick(() => {
      let header =
        document.querySelector('#header') === null
          ? 0
          : document.querySelector('#header').offsetHeight
      let noticBar =
        document.querySelector('#noticBar') === null
          ? 0
          : document.querySelector('#noticBar').offsetHeight
      let height = header + noticBar
      let id = window.location.hash
      if (id) {
        let target = document.querySelector(id)
        if (target) window.scrollTo({ top: target.offsetTop - height })
      }
    })
  },

  methods: {
    isString,
  },
}
</script>
